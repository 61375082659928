/*
H,S,L-10
H,S,L
H,S,L+10
H,S,96
*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Ruda:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

// COLOR PALETTE
// ---------------------------

$factor-dark: 10%;
$factor-darker: 25%;
$factor-light: 10%;
$value-lighter: 96%;

$color-black: #161616;
$color-white: white;

$box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
$box-shadow-hover: rgba(0, 0, 0, 0.16) 0 1px 12px;

$micromate-opacity-background-color: rgba(0, 0, 0, 0.2);

$z-index-input-spinner-element: 2;
$z-index-registration: 100;

$color-red: #fa5557;
$color-yellow: #FFCE4A;
$color-green: #A1D189;
$color-darkgreen: #5C8A45;
$color-orange: #F8B941;
$color-violet: #7B83EB;
$color-darkviolet: #464EB8;
$color-magenta: #8DDFDC;
$color-blue: #95abbf;
$color-darkblue: #334455;
$color-grey: #d2d2d2;
$color-darkgrey: #323232;
$color-pink: #CC2E7E;

$color-red-dark: darken($color-red, $factor-dark);
$color-yellow-dark: darken($color-yellow, $factor-dark);
$color-green-dark: darken($color-green, $factor-dark);
$color-darkgreen-dark: darken($color-darkgreen, $factor-dark);
$color-orange-dark: darken($color-orange, $factor-dark);
$color-violet-dark: darken($color-violet, $factor-dark);
$color-magenta-dark: darken($color-magenta, $factor-dark);
$color-blue-dark: darken($color-blue, $factor-dark);
$color-darkblue-dark: darken($color-darkblue, $factor-dark);
$color-grey-dark: darken($color-grey, $factor-dark);
$color-darkgrey-dark: darken($color-darkgrey, $factor-dark);
$color-pink-dark: darken($color-pink, $factor-light);

$color-grey-darker: darken($color-grey, $factor-darker);

$color-red-light: lighten($color-red, $factor-light);
$color-yellow-light: lighten($color-yellow, $factor-light);
$color-green-light: lighten($color-green, $factor-light);
$color-darkgreen-light: lighten($color-darkgreen, $factor-light);
$color-orange-light: lighten($color-orange, $factor-light);
$color-violet-light: lighten($color-violet, $factor-light);
$color-magenta-light: lighten($color-magenta, $factor-light);
$color-blue-light: lighten($color-blue, $factor-light);
$color-darkblue-light: lighten($color-darkblue, $factor-light);
$color-grey-light: lighten($color-grey, $factor-light);
$color-darkgrey-light: lighten($color-darkgrey, $factor-light);
$color-pink-light: lighten($color-pink, $factor-light);

$color-red-lighter: change-color($color-red, $lightness: $value-lighter);
$color-yellow-lighter: change-color($color-yellow, $lightness: $value-lighter);
$color-green-lighter: change-color($color-green, $lightness: $value-lighter);
$color-darkgreen-lighter: change-color($color-darkgreen, $lightness: $value-lighter);
$color-orange-lighter: change-color($color-orange, $lightness: $value-lighter);
$color-violet-lighter: change-color($color-violet, $lightness: $value-lighter);
$color-magenta-lighter: change-color($color-magenta, $lightness: $value-lighter);
$color-blue-lighter: change-color($color-blue, $lightness: $value-lighter);
$color-darkblue-lighter: change-color($color-darkblue, $lightness: $value-lighter);
$color-grey-lighter: change-color($color-grey, $lightness: $value-lighter);
$color-darkgrey-lighter: change-color($color-darkgrey, $lightness: $value-lighter);
$color-pink-lighter: change-color($color-pink, $lightness: $value-lighter);

// COLORS
// ---------------------------

$color-font-dark: $color-darkgrey-dark;
$color-font-bright: $color-grey-lighter;

// MARGIN / PADDING
// ---------------------------

$micromate-margin-very-small: 4px;
$micromate-margin-small: 8px;
$micromate-margin-normal: 16px;
$micromate-margin-big: 32px;
$micromate-margin-very-big: 64px;

// FONTS
// ---------------------------
$font-text-default: 'Open Sans', sans-serif;
$font-text: var(--micromate-font, $font-text-default);

$font-title-default: 'Ruda', sans-serif;
$font-title: var(--micromate-font-title, $font-title-default);

$micromate-font-size-very-small: 8px;
$micromate-font-size-small: 12px;
$micromate-font-size-small-normal: 14px;
$micromate-font-size-normal: 16px;
$micromate-font-size-big: 24px;
$micromate-font-size-very-big: 32px;

// RESPONSIVE
// ---------------------------

$mobile-width: 768px;
$mobile-width-editor: 1030px;
$tablet-width: 1030px;
$tablet-width-editor: 1225px;

$mobile-height: 500px;

// Elements
$header-height: 60px;
$navigation-height: 60px;

// Z-INDEXES
// ---------------------------

$z-index-popup: 15;
