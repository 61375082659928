@import "projects/micromate-learn-lib/src/lib/theme/reset.scss";
@import 'projects/micromate-learn-lib/src/lib/theme/micromate-theme.scss';

html, body {
    height: 100%;
}

h2 {
    font-size: 24px;
    font-family: $font-title;
}

.micromate-profile-page {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.micromate-learn-component {
    background-color: $color-darkgrey;
    height: calc(100% - $header-height - $navigation-height);
    width: 100%;
    display: flex;
}

.micromate-learn-context {
    position: relative;
    width: 70%;
    min-width: 500px;
    margin-left: auto;
    margin-right: auto;
    background-color: $color-white;
    height: calc(100% - 16px);
    overflow: auto;
    border-bottom-left-radius: $micromate-margin-small;
    border-bottom-right-radius: $micromate-margin-small;
    box-shadow: $box-shadow-hover;

    .micromate-profile-page {
        width: auto;
        height: fit-content;
    }
}

@media screen and (max-width: 768px) {
    .micromate-learn-context {
        width: 100%;
        min-width: unset;
        height: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .micromate-learn-component {
        height: calc(100% - $header-height);
    }
}

* {
    font-size: 14px;
    font-family: $font-text;
    color: $color-font-dark;
}

h1, h2, h6 {
    font-size: 36px;
    font-family: $font-title;
}

h5 {
    font-size: 16px;
    font-family: $font-title;
}

h3 {
    font-size: 24px;
    font-family: $font-title;
}

strong {
    font-weight: bold;
}

*::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2)
}

*::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.08)
}
